import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Paragraph from "../../components/common/Typography/Paragraph";
import { Link } from "react-router-dom";

const HelpCenterLink = ({ data }) => {
  const navigate = useNavigate();
  const [dropdown, setDropdown] = useState(false);
  // console.log("data", data);
  return (
    <div className="mb-2">
      {!data.dropdownList && data.title && (
        <div
          onClick={() => {
            navigate(data.link, {
              state: data,
            });
          }}
        >
          <Paragraph
            variant={"MainParagraph"}
            className="text-offblack hover:text-primaryblue cursor-pointer"
          >
            {data.title}
          </Paragraph>
        </div>
      )}
      {!data.dropdownList && data.LinkingTitle && (
        <Link to={data.link}>
          <Paragraph
            variant={"MainParagraph"}
            className={"hover:text-primaryblue capitalize"}
          >
            {data.LinkingTitle}
          </Paragraph>
        </Link>
      )}
      {data.dropdownList && (
        <div
          onClick={() => {
            setDropdown(!dropdown);
          }}
        >
          <Paragraph
            variant={"MainParagraph"}
            className="text-offblack hover:text-primaryblue cursor-pointer capitalize"
          >
            {data.title}
          </Paragraph>
        </div>
      )}
      {data.dropdownList && dropdown && (
        <ul className="mt-2">
          {data.dropdownList.map((e) => {
            return (
              <>
                <li
                  onClick={() => {
                    const isExternalLink =
                      e.link.startsWith("http") || e.link.startsWith("https");

                    if (isExternalLink) {
                      // For external links, open in a new tab
                      window.open(e.link, "_blank");
                    } else {
                      // For internal links, use React Router's navigate function
                      navigate(e.link, {
                        state: e,
                      });
                    }
                  }}
                >
                  <div className="flex gap-2">
                    <i className="fi fi-ss-bullet text-xs text-offblack mt-[3px]"></i>
                    <Paragraph
                      variant={"MainParagraph"}
                      className="text-offblack hover:text-primaryblue cursor-pointer mb-1 capitalize"
                    >
                      {e.dropdownTitle}
                    </Paragraph>
                  </div>
                </li>
              </>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default HelpCenterLink;
