import React from "react";
import MainBanner from "../sections/Home/MainBanner";
// import SuccessSlider from "../sections/Home/SuccessSlider";
import Services from "../sections/Home/Services";
import Blogs from "../sections/Home/Blogs";
import { Footer } from "../ComponentFile";
import LatestUpdates from "../sections/Home/LatestUpdates";
import BusinesEmpowerment from "../sections/Home/BusinesEmpowerment";

const Home = () => {
  return (
    <>
      <MainBanner />
      {/* <SuccessSlider /> */}
      <Services />
      <LatestUpdates />
      <BusinesEmpowerment />
      <Blogs />
      <Footer />
    </>
  );
};

export default Home;
