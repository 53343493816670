import React, { useState } from "react";
import MainButton from "../../../components/common/MainButton/MainButton";
import { Link, useNavigate } from "react-router-dom";
import BusinessFAQs from "./BusinessFAQs";
import { MultiSelect } from "primereact/multiselect";
import useAuth from "../../../Hooks/useAuth";
import TextFiled from "../../../components/common/InputField/TextFiled";
import TextArea from "../../../components/common/InputField/TextArea";
import InteractiveText from "../../../components/common/Typography/InteractiveText";
import { Chips } from "primereact/chips";
import Snippet from "../../../components/common/SnippetIcon/Snippet";
import SpecialText from "../../../components/common/Typography/SpecialText";
import { Field, ErrorMessage } from "formik";

const Account = ({ setFieldValue, values, errors }) => {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const [descriptionLength, setDescriptionLength] = useState(0);

  const handleDescriptionChange = (e, setFieldValue) => {
    const { value } = e.target;
    setDescriptionLength(value.length); // Update character count
    setFieldValue("businessDescription", value); // Update Formik value
  };
  const servicesNames = [
    { name: "Air conditioning & Heating" },
    { name: "Architectural Services" },
    { name: "Bricklaying & Blockwork" },
    { name: "Cabinet Making" },
    { name: "Carpentry" },
    { name: "Caulking Services" },
    { name: "Cleaning Services" },
    { name: "Concrete & Paving" },
    { name: "Conveyancing" },
    { name: "Decking, Gazebo & Carports" },
    { name: "Demolition & Excavation" },
    { name: "Electrical Services" },
    { name: "Engineering Services" },
    { name: "Fencing & Gates" },
    { name: "Garden & Landscaping" },
    { name: "Glass & Glazing" },
    { name: "Handyman Services" },
    { name: "Inspections & Surveyors" },
    { name: "Insulation Services" },
    { name: "Interior Design" },
    { name: "Locksmiths" },
    { name: "Mould & Asbestos" },
    { name: "Moving & Delivery" },
    { name: "Painting & Decorating" },
    { name: "Pest Control" },
    { name: "Plumbing" },
    { name: "Pools & Spas" },
    { name: "Rendering" },
    { name: "Renovation & Restoration" },
    { name: "Roofing" },
    { name: "Scaffolding" },
    { name: "Shopfitting" },
    { name: "Skip Bins" },
    { name: "Solar Power" },
    { name: "Staircase Services" },
    { name: "Stonework" },
    { name: "Tiling" },
    { name: "Waterproofing" },
  ];
  return (
    <>
      <div className="w-[320px] sm:w-[380px] md:w-[400px] lg:w-[420px] xl:w-[420px] 2xl:w-[500px] mx-auto">
        <Field name="ABN">
          {({ field, form }) => (
            <>
              <div>
                <TextFiled
                  {...field}
                  className={"lg:bg-pastalblue"}
                  variant={"large"}
                  label={"ABN"}
                  onChange={(e) => setFieldValue("ABN", e.target.value)}
                  value={values.ABN}
                  name="ABN"
                  placeholder={"Enter your Australian Business Number (ABN)"}
                  star={true}
                />
                <div className="flex items-center justify-between w-[320px] sm:w-[380px] md:w-[400px] lg:w-[420px] xl:w-[420px] 2xl:w-[500px] text-primaryblue mt-2">
                  <SpecialText
                    variant={"FootNoteDisclaimer"}
                    className={" text-alertred"}
                  >
                    {form.errors.ABN}
                  </SpecialText>
                  <Link to={"https://abr.business.gov.au/"} target="_blank">
                    <InteractiveText variant={"ActionLink"}>
                      Look up (ABN)
                    </InteractiveText>
                  </Link>
                </div>
              </div>
            </>
          )}
        </Field>
        <div className="mb-5 mt-1">
          <Field name="companyName">
            {({ field, form }) => (
              <>
                <TextFiled
                  {...field}
                  className={"lg:bg-pastalblue"}
                  variant={"large"}
                  onChange={(e) => setFieldValue("companyName", e.target.value)}
                  value={values.companyName}
                  name="companyName"
                  label={"Company name"}
                  placeholder={
                    "Enter the legal name of your company (Optional)"
                  }
                />
                <SpecialText
                  variant={"FootNoteDisclaimer"}
                  className={" text-alertred mt-2"}
                >
                  {form.errors.companyName}
                </SpecialText>
              </>
            )}
          </Field>
        </div>
        <Field name="businessName">
          {({ field, form }) => (
            <div>
              <TextFiled
                {...field}
                className={"lg:bg-pastalblue"}
                variant={"large"}
                label={"Business name"}
                onChange={(e) => setFieldValue("businessName", e.target.value)}
                value={values.businessName}
                name="businessName"
                placeholder={
                  "Enter the name under which your business operates"
                }
                star={true}
              />
              <SpecialText
                variant={"FootNoteDisclaimer"}
                className={" text-alertred mt-2"}
              >
                {form.errors.businessName}
              </SpecialText>
            </div>
          )}
        </Field>

        <div className="mt-4">
          <div className="flex">
            <Snippet
              variant={"label"}
              snippet={true}
              star={true}
              text={
                "Select the main category that best represents your business, for example, carpentry."
              }
              label={"Primary business category"}
            />
          </div>
          <Field name="dropdown1">
            {({ field, form }) => (
              <>
                <div className="custom-multiselect">
                  <MultiSelect
                    {...field}
                    value={values.dropdown1}
                    onChange={(e) => setFieldValue("dropdown1", e.value)}
                    options={servicesNames}
                    optionLabel="name"
                    selectionLimit={1}
                    showSelectAll={false}
                    filter
                    filterBy="name"
                    placeholder="Select business category"
                    display="chip"
                    style={{
                      border: "none",
                      color: "rgb(35 42 46)",
                    }}
                    panelClassName="custom-multiselect-panel"
                    className={`custom-multiselect-bg text-offblack items-center focus:border block font-inter font-normal normal-case text-[11px] leading-[1.4] tracking-[0px] sm:text-[11px] md:text-[12px] lg:text-[12px] xl:text-[12px] 2xl:text-[13px] 3xl:text-[13px] rounded-lg w-full h-[46px] sm:h-[50px] md:h-[54px] lg:h-[56px] py-1 px-2.5 `}
                  />
                  <i className="fi fi-rs-angle-down text-xs lg:text-sm text-offblack hover:cursor-pointer custom-multiselect-icon"></i>
                </div>
                {form.touched.dropdown1 && (
                  <SpecialText
                    variant={"FootNoteDisclaimer"}
                    className="error-message text-alertred mt-2"
                  >
                    {form.errors.dropdown1}
                  </SpecialText>
                )}
              </>
            )}
          </Field>
        </div>
        <div className="mt-5">
          <div className="flex">
            <Snippet
              variant={"label"}
              snippet={true}
              star={true}
              text={
                "Select up to two secondary categories to further define your business, like Landscaping, Carpentry, Electrical etc."
              }
              label={"Secondary business category"}
            />
          </div>
          <Field name="dropdown2">
            {({ field, form }) => (
              <>
                <div className="custom-multiselect">
                  <MultiSelect
                    {...field}
                    value={values.dropdown2}
                    onChange={(e) => setFieldValue("dropdown2", e.value)}
                    options={servicesNames}
                    optionLabel="name"
                    selectionLimit={2}
                    showSelectAll={false}
                    filter
                    filterBy="name"
                    placeholder="Select secondary business categories"
                    display="chip"
                    style={{
                      border: "none",
                      color: "rgb(35 42 46)",
                    }}
                    panelClassName="custom-multiselect-panel"
                    className={`custom-multiselect-bg text-offblack items-center focus:border block font-inter font-normal normal-case text-[11px] leading-[1.4] tracking-[0px] sm:text-[11px] md:text-[12px] lg:text-[12px] xl:text-[12px] 2xl:text-[13px] 3xl:text-[13px] rounded-lg w-full h-[46px] sm:h-[50px] md:h-[54px] lg:h-[56px] py-1 px-2.5 `}
                  />
                  <i className="fi fi-rs-angle-down text-xs lg:text-sm text-offblack hover:cursor-pointer custom-multiselect-icon"></i>
                </div>
                {form.touched.dropdown2 && (
                  <SpecialText
                    variant={"FootNoteDisclaimer"}
                    className="error-message text-alertred mt-2"
                  >
                    {form.errors.dropdown2}
                  </SpecialText>
                )}
              </>
            )}
          </Field>
        </div>
        <div className="mt-5">
          <Field name="location">
            {({ field, form }) => (
              <div>
                <TextFiled
                  {...field}
                  className={"lg:bg-pastalblue"}
                  variant={"large"}
                  onChange={(e) => setFieldValue("location", e.target.value)}
                  value={values.location}
                  name="location"
                  label={"Location"}
                  placeholder={"Type your Suburb or post code"}
                  star={true}
                />
                <SpecialText
                  variant={"FootNoteDisclaimer"}
                  className={" text-alertred mt-2"}
                >
                  {form.errors.location}
                </SpecialText>
              </div>
            )}
          </Field>
        </div>
        <div className="mt-5">
          <Field name="officeNumber">
            {({ field, form }) => (
              <div>
                <TextFiled
                  {...field}
                  className={"lg:bg-pastalblue"}
                  variant={"large"}
                  onChange={(e) =>
                    setFieldValue("officeNumber", e.target.value)
                  }
                  value={values.officeNumber}
                  name="officeNumber"
                  label={"Office number"}
                  placeholder={"Enter your office phone number"}
                />
              </div>
            )}
          </Field>
        </div>
        <div className="mt-5">
          <Field name="mobileNumber">
            {({ field, form }) => (
              <div>
                <TextFiled
                  {...field}
                  className={"lg:bg-pastalblue"}
                  variant={"large"}
                  onChange={(e) =>
                    setFieldValue("mobileNumber", e.target.value)
                  }
                  value={values.mobileNumber}
                  name="mobileNumber"
                  label={"Mobile number"}
                  placeholder={"Enter your business mobile number"}
                  star={true}
                />
                <SpecialText
                  variant={"FootNoteDisclaimer"}
                  className={" text-alertred mt-2"}
                >
                  {form.errors.mobileNumber}
                </SpecialText>
              </div>
            )}
          </Field>
        </div>
        <div className="mt-5">
          <Field name="businessEmail">
            {({ field, form }) => (
              <div>
                <TextFiled
                  {...field}
                  className={"lg:bg-pastalblue"}
                  variant={"large"}
                  onChange={(e) =>
                    setFieldValue("businessEmail", e.target.value)
                  }
                  value={values.businessEmail}
                  name="businessEmail"
                  label={"Business email"}
                  placeholder={"Enter your business email address"}
                />
                <SpecialText
                  variant={"FootNoteDisclaimer"}
                  className={" text-alertred mt-2"}
                >
                  {form.errors.businessEmail}
                </SpecialText>
              </div>
            )}
          </Field>
        </div>
        <div className="mt-5">
          <Field name="businessWebsite">
            {({ field, form }) => (
              <div>
                <TextFiled
                  {...field}
                  className={"lg:bg-pastalblue"}
                  variant={"large"}
                  onChange={(e) =>
                    setFieldValue("businessWebsite", e.target.value)
                  }
                  value={values.businessWebsite}
                  name="businessWebsite"
                  label={"Business website"}
                  placeholder={"Enter your business website address"}
                />
                <SpecialText
                  variant={"FootNoteDisclaimer"}
                  className={" text-alertred mt-2"}
                >
                  {form.errors.businessWebsite}
                </SpecialText>
              </div>
            )}
          </Field>
        </div>
        <div className="mt-5">
          <Snippet variant={"label"} label={"Trade skills "} />
          <Chips
            id="addyourtags"
            placeholder={"Add your trade skills"}
            className="custom-chips-bg placeholder-lightgray text-offblack font-inter font-normal normal-case text-[11px] leading-[1.4] tracking-[0px] sm:text-[11px] md:text-[12px] lg:text-[12px] xl:text-[12px] 2xl:text-[13px] 3xl:text-[13px] w-full min-h-[46px] sm:min-h-[50px] md:min-h-[54px] lg:min-h-[56px] rounded-lg"
            value={values.tradeSkills || []} // Assuming `postTags` is in `values`
            onChange={(e) => setFieldValue("tradeSkills", e.value)}
            // separator=","
          />
        </div>
        <div className="mt-5">
          <BusinessFAQs />
        </div>
        <div className="mt-5">
          <Field name="businessDescription">
            {({ field, form }) => (
              <>
                <TextArea
                  {...field}
                  variant={"large"}
                  className="lg:bg-pastalblue"
                  name="businessDescription"
                  label={"Business description"}
                  placeholder={
                    "Provide a detailed description of your business."
                  }
                  star={true}
                  limitation={true}
                  value={values.businessDescription}
                  onChange={(e) => handleDescriptionChange(e, setFieldValue)}
                  letterCounter={descriptionLength ?? 0}
                  maxCount={400}
                />
                <SpecialText
                  variant={"FootNoteDisclaimer"}
                  className={"mt-2 text-alertred"}
                >
                  {form.errors.businessDescription}
                </SpecialText>
              </>
            )}
          </Field>
        </div>
        <div className="flex items-center justify-end mt-10 w-full">
          <MainButton
            type={"submit"}
            variant={"small"}
            onClick={() => {
              if (values) {
                console.log("Values", values);
              }
            }}
          >
            Save
          </MainButton>
        </div>
      </div>
      <div className="mt-10 w-[320px] sm:w-[380px] md:w-[400px] lg:w-[420px] xl:w-[420px] 2xl:w-[500px] border-t border-t-mediumgray pt-10">
        <SpecialText variant={"FootNoteDisclaimer"}>
          Remember, this information is necessary to ensure the highest quality
          of service on Trades Poster. It enables customers to trust the
          professionals they hire through our platform. Thank you for your
          cooperation and understanding
        </SpecialText>
        <div className="flex items-center justify-center mt-10 w-full">
          <MainButton
            variant={"specialredbuttonmedium"}
            onClick={() => navigate("/business-dashboard/account-deactivation")}
          >
            Deactivate
          </MainButton>
        </div>
      </div>
    </>
  );
};

export default Account;
