import { motion } from "framer-motion";
import React from "react";
import { useNavigate } from "react-router-dom";

const Popup = (props) => {
  const navigate = useNavigate();
  const dropIn = {
    visible: {
      y: "0",
      opacity: 1,
      transition: {
        duration: 0.9,
        type: "ease",
        damping: 25,
        stiffness: 500,
      },
    },
    exit: {
      y: "100vh",
      opacity: 0,
      transition: {
        duration: 0.9,
        type: "ease",
        damping: 25,
        stiffness: 500,
      },
    },
  };
  return (
    <motion.div
      variants={dropIn}
      initial="hidden"
      animate="visible"
      exit="exit"
      className={`fixed top-0 left-0 w-screen h-screen flex flex-col justify-center items-center z-50 bg-pastalblue hidden-scrollbar`}
    >
      <div
        className={`fixed w-screen h-screen top-0 z-40 hidden-scrollbar ${props.className}`}
      >
        {!props.headingSection && (
          <>
            <div
              className={
                "flex items-center justify-between mx-[5%] xl:mx-auto xl:w-[1100px] 2xl:w-[1264px] h-[60px] sm:h-[80px]"
              }
            >
              <div onClick={props.HandleLogoClick}>
                <img
                     className="w-[60px] h-[25px] sm:w-[65px] sm:h-[28px] lg:w-[70px] lg:h-[30px] cursor-pointer"
                  src={require("../../assets/img/trades-poster.png")}
                  alt="trade-poster"
                />
              </div>
              <div
                onClick={() => {
                  props.setTrigger(false);
                  props.onClose();
                }}
              >
                <div
                  className={`${!props?.ShowCrossButton ? "hidden" : "block"}`}
                >
                  {props.cross ? (
                    <>
                      <i className="fi fi-rs-cross text-base text-offblack cursor-pointer"></i>
                    </>
                  ) : (
                    <i className="fi fi-rs-cross text-base text-offblack cursor-pointer"></i>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
        <div>{props.children}</div>
      </div>
    </motion.div>
  );
};
export default Popup;
