import React from "react";

const Stepper = ({ currentStep, totalSteps, marginTop }) => {
  const renderSteps = () => {
    const steps = [];
    for (let i = 1; i <= totalSteps; i++) {
      steps.push(
        <svg
          key={i}
          width="39"
          height="4"
          viewBox="0 0 39 4"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            width="39"
            height="4"
            rx="2"
            fill={i <= currentStep ? '#2E00FF' : '#DCDCDC'} // Blue if within steps, black otherwise
          />
        </svg>
      );
    }
    return steps;
  };
  return (
    <>
      <div className={`flex items-center justify-center gap-1 ${marginTop}`}>
      {renderSteps()}
    </div>
    </>
  );
};

export default Stepper;
