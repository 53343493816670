import React, { useEffect, useState } from "react";
import DefineCategory from "../../../sections/BusinessDashboard/Post/CreatePost/DefineCategory";
import SelectPostType from "../../../sections/BusinessDashboard/Post/CreatePost/SelectPostType";
import LocationandPortfolio from "../../../sections/BusinessDashboard/Post/CreatePost/LocationandPortfolio";
import PostReview from "../../../sections/BusinessDashboard/Post/CreatePost/PostReview";
import Heading from "../../../components/common/Typography/Heading";
import DashboardMainContainer from "../../../components/common/Containers/DashboardMainContainer";
import PostDetails from "../../../sections/BusinessDashboard/Post/CreatePost/PostDetails";

import { Formik, Form } from "formik";
import {
  defaultValues,
  validationSchema,
} from "../../../FormValidations/Latest/CreatePost";
import Stepper from "../../../components/common/StepIndicater/Stepper";

const CreatePost = () => {
  const [step, setStep] = useState(1);

  // Set initial values on mount
  const [initialValues, setInitialValues] = useState(defaultValues);

  useEffect(() => {
    const savedData =
      JSON.parse(localStorage.getItem("formData")) || defaultValues;
    setInitialValues(savedData);
  }, []);

  const handleSubmit = (values) => {
    console.log("Form data:", values);
  };
  return (
    <>
      <DashboardMainContainer
        className={"lg:px-5 lg:pb-10 pt-[33px] min-h-screen"}
      >
        <div>
          {step === 1 && (
            <Heading variant={"h6"} className={"text-center text-offblack"}>
              Select type
            </Heading>
          )}
          {step === 2 && (
            <Heading variant={"h6"} className={"text-center text-offblack"}>
              Define category
            </Heading>
          )}
          {step === 3 && (
            <Heading variant={"h6"} className={"text-center text-offblack"}>
              Add details
            </Heading>
          )}
          {step === 4 && (
            <Heading variant={"h6"} className={"text-center text-offblack"}>
              Set location
            </Heading>
          )}
          {step === 5 && (
            <Heading variant={"h6"} className={"text-center text-offblack"}>
              Review and publish
            </Heading>
          )}
        </div>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize // Ensures reinitialization of form values on step change
        >
          {({ setFieldValue, values }) => (
            <Form>
              {/* Render SelectPostType component for step 1 */}
              {step === 1 && (
                <SelectPostType
                  setFieldValue={setFieldValue}
                  values={values}
                  setStep={setStep}
                />
              )}

              {/* Render DefineCategory component for step 2 */}
              {step === 2 && (
                <DefineCategory
                  values={values}
                  setFieldValue={setFieldValue}
                  setStep={setStep}
                />
              )}

              {/* Render PostDetails component for step 3 */}
              {step === 3 && (
                <PostDetails
                  values={values}
                  setFieldValue={setFieldValue}
                  setStep={setStep}
                />
              )}

              {/* Render LocationandPortfolio and PortfolioUploading components for step 4 */}
              {step === 4 && (
                <div>
                  <LocationandPortfolio
                    values={values}
                    setFieldValue={setFieldValue}
                    setStep={setStep}
                  />
                </div>
              )}

              {/* Render PostReview component for step 5 */}
              {step === 5 && <PostReview values={values} setStep={setStep} />}
            </Form>
          )}
        </Formik>

        {/* Stepper rendering */}
        <Stepper currentStep={step} totalSteps={5} marginTop={"mt-20"}/>
      </DashboardMainContainer>
    </>
  );
};

export default CreatePost;
