import React from "react";
import Heading from "../../components/common/Typography/Heading";
import CountUp from "react-countup";

const Percentaging = ({textColor, number, text}) => {
  return (
    <>
      <div>
        <h1
          className={`font-inter font-bold text-[150px] xl:text-[200px] text-center animated-text ${textColor}`}
        >
          <CountUp start={0} end={number} delay={5}/>%
        </h1>
        <Heading
          variant={"h6"}
          className={"text-center text-offblack mt-3"}
        >
          {text}
        </Heading>
      </div>
    </>
  );
};

export default Percentaging;
