import React from "react";

const SpecialText = ({ variant, children, className }) => {
  return (
    <>
      {/* Usage : Used on Pricing cards package price */}
      {variant === "ExtraLargeNumber" && (
        <p
          className={`
          ${className} font-inter font-bold leading-[1.4] tracking-[0px] text-[32px] sm:text-[38px] md:text-[42px] lg:text-[40px] xl:text-[46px] 2xl:text-[48px] 3xl:text-[48px]`}
        >
          {children}
        </p>
      )}

      {/* Usage : Used on Rankings like 4.1 by 5 */}
      {variant === "LargeNumber" && (
        <p
          className={`
          ${className} font-inter font-bold leading-[1.4] tracking-[0px] text-[23px] sm:text-[28px] md:text-[32px] lg:text-[28px] xl:text-[32px] 2xl:text-[34px] 3xl:text-[34px]`}
        >
          {children}
        </p>
      )}
       {/* Usage : Counter for dashboards tabs */}
       {variant === "TabsCounter" && (
        <p
          className={`
          ${className} font-inter font-bold text-[17px] leading-[1.4] tracking-[0px] sm:text-[17px] md:text-[17px] lg:text-[18px] xl:text-[19px] 2xl:text-[20px]`}
        >
          {children}
        </p>
      )}
      {/* o	Usage: Fine print, disclaimers, legal notes. snips etc */}
      {variant === "FootNoteDisclaimer" && (
        <p
          className={`
          ${className} font-inter font-normal leading-[1.4] tracking-[0px] text-[9px] sm:text-[10px] md:text-[11px] lg:text-[10px] xl:text-[10px] 2xl:text-[11px] 3xl:text-[11px]`}
        >
          {children}
        </p>
      )}

      {/* o	Usage: Displaying user or company names */}
      {variant === "ProfileName" && (
        <p
          className={`
          ${className} font-inter font-normal capitalize leading-[1.4] tracking-[0px] text-[15px] sm:text-[15px] md:text-[16px] lg:text-[16px] xl:text-[16px] 2xl:text-[17px] 3xl:text-[17px]`}
        >
          {children}
        </p>
      )}

      {/* o	Usage: Displaying user or company occupations and locations */}
      {variant === "OccupationLocation" && (
        <p
          className={`
          ${className} font-inter font-normal leading-[1.4] tracking-[0px] text-[11px] sm:text-[12px] md:text-[13px] lg:text-[12px] xl:text-[12px] 2xl:text-[13px] 3xl:text-[13px]`}
        >
          {children}
        </p>
      )}

      {/* o	Usage: Displaying the date of joining for users or companies */}
      {variant === "Date" && (
        <p
          className={`
          ${className} font-inter font-normal leading-[1.4] tracking-[0px] text-[10px] sm:text-[11px] md:text-[12px] lg:text-[11px] xl:text-[11px] 2xl:text-[12px] 3xl:text-[12px]`}
        >
          {children}
        </p>
      )}

      {/* o	Usage: Displaying when needs paragraph size text or low (value /0 ) Labelling. */}
      {/* {variant === "ParagraphSizeNumber" && (
        <p
          className={`
          ${className} font-inter font-normal text-[12px] leading-[1.4] tracking-[0px] sm:text-[12px] md:text-[12px] lg:text-[13px] xl:text-[14px] 2xl:text-[15px]`}
        >
          {children}
        </p>
      )} */}

      {/* o	Usage: Displaying counter for services cards, icons Numbers, Review counter number. */}
      {variant === "Counter" && (
        <p
          className={`
          ${className} font-inter font-normal leading-[1.4] tracking-[0px] text-[10px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[11px] 2xl:text-[12px] 3xl:text-[12px]`}
        >
          {children}
        </p>
      )}

      {/* o	Usage: Displaying the dates under reviews or similar only date patch */}
      {variant === "DateStamp" && (
        <p
          className={`
          ${className} font-inter font-normal leading-[1.4] tracking-[0px] text-[10px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[11px] 2xl:text-[12px] 3xl:text-[12px]`}
        >
          {children}
        </p>
      )}
    </>
  );
};

export default SpecialText;
