import React from "react";

const Paragraph = ({ variant, children, className }) => {
  return (
    <>
     

      {/* Latest Paragraphs Specifications */}

      {/* o	Usage: Default style for all main content */}
      {variant === "MainParagraph" && (
        <p
          className={`
          ${className} font-inter leading-[1.4] tracking-[0px] text-[13px] sm:text-[13px] md:text-[14px] lg:text-[14px] xl:text-[14px] 2xl:text-[15px] 3xl:text-[15px]`}
        >
          {children}
        </p>
      )}

      {/* o	Usage: Styling for footer sub text/paragraph  */}
      {variant === "FooterParagraph" && (
        <p
          className={`
          ${className} font-inter capitalize leading-[1.4] tracking-[0px] text-[11px] sm:text-[11px] md:text-[12px] lg:text-[12px] xl:text-[12px] 2xl:text-[13px] 3xl:text-[13px]`}
        >
          {children}
        </p>
      )}
    </>
  );
};
export default Paragraph;
