import React from "react";
import { Dropdown } from "primereact/dropdown";
import { Field, ErrorMessage } from "formik";
import Snippet from "../../../../components/common/SnippetIcon/Snippet";
import InteractiveText from "../../../../components/common/Typography/InteractiveText";
import MainButton from "../../../../components/common/MainButton/MainButton";
import { LatestData } from "./Dataset";
import SpecialText from "../../../../components/common/Typography/SpecialText";

const DefineCategory = ({ values, setFieldValue, setStep }) => {
  const subListing = LatestData.find(
    (item) => item.primaryCategory === values.dropdown1.primaryCategory
  );

  const sortedLatestData = LatestData.sort((a, b) => {
    if (a.primaryCategory < b.primaryCategory) return -1;
    if (a.primaryCategory > b.primaryCategory) return 1;
    return 0;
  });

  const handleSubcategoryChange = (e) => {
    // Set the selected subcategory
    setFieldValue("dropdown2", e.value);

    // Get the corresponding keywords for the selected subcategory
    const selectedSubcategory = subListing.subList.find(
      (sub) => sub.name === e.value.name
    );
    if (selectedSubcategory) {
      setFieldValue("postTags", selectedSubcategory.keywords || []);
    }
  };

  return (
    <div className="w-[320px] sm:w-[380px] md:w-[400px] lg:w-[420px] xl:w-[420px] 2xl:w-[500px] mx-auto mt-[60px]">
      <div>
        <div className="flex">
          <Snippet
            variant={"label"}
            label={"Select Primary Post Category"}
            text={
              "Select your primary post category from the available options."
            }
            star={true}
            snippet={true}
          />
        </div>

        {/* Dropdown for Primary Category */}
        <Field name="dropdown1">
          {({ field, form }) => (
            <>
              <div className="custom-dropdown">
                <Dropdown
                  {...field}
                  value={values.dropdown1}
                  onChange={(e) => setFieldValue("dropdown1", e.value)}
                  options={sortedLatestData}
                  optionLabel="primaryCategory"
                  placeholder="Post primary category"
                  panelClassName="custom-dropdown-panel"
                  className="custom-dropdown-field p-dropdown-trigger-custom text-offblack flex items-center font-inter font-normal normal-case text-[11px] leading-[1.4] tracking-[0px] sm:text-[11px] md:text-[12px] lg:text-[12px] xl:text-[12px] 2xl:text-[13px] 3xl:text-[13px] rounded-lg w-full h-[46px] sm:h-[50px] md:h-[54px] lg:h-[56px] "
                />
                <i className="fi fi-rs-angle-down text-xs lg:text-sm text-offblack hover:cursor-pointer custom-dropdown-icon"></i>
              </div>
              {form.touched.dropdown1 && (
                <SpecialText
                  variant={"FootNoteDisclaimer"}
                  className="error-message text-alertred mt-2"
                >
                  {form.errors.dropdown1}
                </SpecialText>
              )}
            </>
          )}
        </Field>
      </div>

      <div className="mt-5">
        <div className="flex">
          <Snippet
            variant={"label"}
            label={"Select Post Sub Category"}
            text={"Select your post sub category from the available options."}
            star={true}
            snippet={true}
          />
        </div>

        {/* Dropdown for Sub Category */}
        <Field name="dropdown2">
          {({ field, form }) => (
            <>
              <div className="custom-dropdown">
                <Dropdown
                  {...field}
                  value={values.dropdown2}
                  onChange={handleSubcategoryChange} // Handle subcategory change
                  options={subListing ? subListing.subList : []}
                  optionLabel="name"
                  placeholder="Post sub category"
                  panelClassName="custom-dropdown-panel"
                  className="custom-dropdown-field p-dropdown-trigger-custom text-offblack flex items-center font-inter font-normal normal-case text-[11px] leading-[1.4] tracking-[0px] sm:text-[11px] md:text-[12px] lg:text-[12px] xl:text-[12px] 2xl:text-[13px] 3xl:text-[13px] rounded-lg w-full h-[46px] sm:h-[50px] md:h-[54px] lg:h-[56px] "
                />
                <i className="fi fi-rs-angle-down text-xs lg:text-sm text-offblack hover:cursor-pointer custom-dropdown-icon"></i>
              </div>
              { form.touched.dropdown2 && (
                <SpecialText
                  variant={"FootNoteDisclaimer"}
                  className="error-message text-alertred mt-2"
                >
                  {form.errors.dropdown2}
                </SpecialText>
              )}
            </>
          )}
        </Field>
      </div>

      <div className="flex items-center justify-between w-[320px] sm:w-[380px] md:w-[400px] lg:w-[420px] xl:w-[420px] 2xl:w-[500px] mt-10">
        <div onClick={() => setStep(1)}>
          <InteractiveText
            variant={"ActionLink"}
            className="text-mediumgray hover:text-primaryblue cursor-pointer"
          >
            Back
          </InteractiveText>
        </div>
        <div>
          <MainButton
            type="submit"
            onClick={() => {
              if (values.dropdown1 && values.dropdown2) {
                setStep(3); // Move to next step if both values are selected
              }
            }}
            variant={"small"}
          >
            Next
          </MainButton>
        </div>
      </div>
    </div>
  );
};

export default DefineCategory;
