import React from "react";
import PriceCard from "../../components/common/PriceCard/PriceCard";
import Heading from "../../components/common/Typography/Heading";

const PricingGuide = () => {
  return (
    <>
      <div className="lg:flex items-center mx-[5%] xl:mx-auto xl:w-[1000px] 2xl:w-[1264px] mt-10 sm:mt-40 gap-x-10">
        <div>
          <Heading variant={"h3"} className={"text-offblack"}>
            Upgrade to the Trades Poster Pro plan and unlock the full power of
            your business. Boost your visibility, build lasting trust, and drive
            greater engagement.
          </Heading>
        </div>
        <div className="flex justify-center lg:justify-end mt-10 lg:mt-0">
          <PriceCard
            tagging={true}
            bottomLine={"border-b border-b-lightgray pb-5"}
            MainDiveClasses={
              "bg-white text-offblack w-[350px] h-[550px] sm:w-[450px] sm:h-[650px] xl:w-[450px] 2xl:w-[500px] 2xl:h-[700px] pb-5 sm:pb-10 xl:pb-[45px]"
            }
            listClasses={"pl-[50px] 2xl:pl-[100px]"}
            pricingColor={"text-offblack"}
            label={"Pro"}
              price={"XX"}
              duration={1}
              description={
                "Gain unmatched visibility, stand out, and grow your business with Pro’s advanced features."
              }
              buttonText={"Upgrade to Pro"}
              listItems={[
                `Priority search placement`,
                `10x More visibility in post`,
                `Unlimited regular posts`,
                `20 Featured posts`,
                `10 Keywords`,
                `Showcase your portfolio`,
                `Advanced profile features`,
                `Contact details & messaging`,
                `Analytics & insights`,
              ]}
              // listItemsValue={[
              //   `${getPremiumPackageDetail?.regularListing}`,
              //   `${getPremiumPackageDetail?.featureListing}`,
              //   `${getPremiumPackageDetail?.noOfImages}`,
              // ]}
            />
        </div>
      </div>
    </>
  );
};

export default PricingGuide;
