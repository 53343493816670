import { useFormik } from "formik";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Flip, toast, ToastContainer } from "react-toastify";
import { ResendVerificationCode } from "../../Api/ResendVerificationCode";
import { SignUp as SignUpAction, VerificationCodeApi } from "../../Api/SignUp";
import TextFiled from "../../components/common/InputField/TextFiled";
import MainButton from "../../components/common/MainButton/MainButton";
import PasswordField from "../../components/common/PasswordField/PasswordField";
import Spinner from "../../components/common/Spinner";
import Popup from "../../components/popup/Popup";
// import VerificationPopUp from "../../sections/VerificationPop/VerificationPopUp";
import Heading from "../../components/common/Typography/Heading";
import SecondaryHeading from "../../components/common/Typography/SecondaryHeading";
// import { MultiSelect } from "primereact/multiselect";
import Paragraph from "../../components/common/Typography/Paragraph";
// import Stepper from "../../components/common/StepIndicater/Stepper";
import {
  InitialValues,
  validationSchema,
} from "../../FormValidations/SignUpSchema";
import useAuth from "../../Hooks/useAuth";
// import "./style.css";
import { useEffect } from "react";
// import { Dropdown } from "primereact/dropdown";
// import Snippet from "../../components/common/SnippetIcon/Snippet";
import AuxiliaryText from "../../components/common/Typography/AuxiliaryText";
import SpecialText from "../../components/common/Typography/SpecialText";
import Verify from "./EmailVerification/Verify";
import CustomerInfo from "./Customer/CustomerInfo";
import BusinessInfo from "./Business/BusinessInfo";
import InteractiveText from "../../components/common/Typography/InteractiveText";
import WrittenStepper from "../../components/common/StepIndicater/WrittenStepper";
// import InteractiveText from "../../components/common/Typography/InteractiveText";
const USER_TYPE = {
  CUSTOMER: "User",
  SELLLER: "Seller",
};
const LOGIN_SOURCE = {
  GMAIL: "Gmail",
  FACEBOOK: "Facebook",
  MANUAL: "Manual",
};
export const SignUp = ({ SignUpState, setSignUpState, SignIn, setSignIn }) => {
  const [EmailVerificationStatus, setEmailVerificationStatus] = useState(false);
  const [ShowCrossButton, setShowCrossButton] = useState(true);
  // const [validCaptcha, setValidCaptcha] = useState(false);
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const [UserType, setUserType] = useState(null);
  const [popup, setPopup] = useState("A");
  const [PasswordType, setPasswordType] = useState("true");
  // const [RepeatPasswordType, setRepeatPasswordType] = useState("true");
  const [VerificationCode, setVerificationCode] = useState();
  const [userDetail, setUserDetail] = useState([{}]);
  const [ResendCode, setResendCode] = useState({
    isCodeSent: false,
  });
  const [VerificationError, setVerificationError] = useState({
    isError: false,
    errorMessage: null,
    isCodeExpire: false,
  });
  const { auth } = useAuth();
  const [RequestStart, setRequetStart] = useState(false);

  const formik = useFormik(
    {
      initialValues: InitialValues,
      validationSchema: validationSchema,
      onSubmit: (values, { resetForm }) => {
        setRequetStart(true);
        // const splitUserName = values.username.split(" ");
        // const firstName = splitUserName?.[0];
        // const lastName = splitUserName?.[1] ?? firstName;

        // if (!validCaptcha) {
        //   setRequetStart(false);
        //   toast.error("reCAPTCHA required!", {
        //     position: toast.POSITION.TOP_RIGHT,
        //     transition: Flip,
        //   });
        // } else {

        const SignUpUser = {
          FirstName: values.email,
          LastName: values.email,
          UserName: values.email,
          Email: values.email,
          ConfirmationCode: values.code,
          Password: values.password,
          ConfirmPassword: values.password,
          Source: LOGIN_SOURCE.MANUAL,
          EmailVerified: false,
          Role: UserType,
        };
        SignUpAction(SignUpUser)
          .then((response) => {
            if (response.status === 200) {
              setRequetStart(false);
              const accessToken = response?.data?.token;
              const roles = response?.data?.userRoles?.$values;
              const user = response?.data?.key;
              const expireAt = response?.data?.tokenExpire;
              const subscription = response?.data?.matrix?.user?.subscription;
              const userDetails = {
                accessToken,
                roles,
                user,
                expireAt,
                subscription,
              };
              console.log("Check", userDetails);
              setUserDetail(userDetails);
              setPopup("D");
              setShowCrossButton(true);
              setEmailVerificationStatus(!EmailVerificationStatus);

              formik.resetForm();
            }
          })
          .catch((error) => {
            console.log("error", error);
          });
      },
    }
    // }
  );

  function VerifyVerificationCodeHandle() {
    setRequetStart(true);
    console.log("Check", userDetail);
    if (!VerificationCode) {
      setRequetStart(false);
      setVerificationError({
        ...VerificationError,
        isError: true,
        errorMessage: "Verification code is required!",
      });
    } else if (VerificationCode) {
      VerificationCodeApi(userDetail?.user?.id, VerificationCode)
        .then((res) => {
          if (res.status === 200) {
            // console.log("Resp", res);
            setRequetStart(false);
            setSignUpState(!SignUpState);
            // setValidCaptcha(false);
            setVerificationCode(null);
            setEmailVerificationStatus(!EmailVerificationStatus);
            toast.success("Registration successfully ", {
              position: toast.POSITION.TOP_RIGHT,
              transition: Flip,
            });
            setUserDetail({
              ...userDetail,
              user: {
                ...userDetail.user,
                emailConfirmed: true,
              },
            });
            setAuth({
              ...userDetail,
              user: {
                ...userDetail.user,
                emailConfirmed: true,
              },
            });
            localStorage.setItem(
              "LoginSession",
              JSON.stringify({
                ...userDetail,
                user: {
                  ...userDetail.user,
                  emailConfirmed: true,
                },
              })
            );
            if (UserType === USER_TYPE.SELLLER) {
              navigate({ pathname: "/Services" }, { replace: true });
            } else navigate({ pathname: from }, { replace: true });
          }
        })
        .catch((error) => {
          if (error.response.data.text === "CODEEXPIRE") {
            setRequetStart(false);
            setVerificationError({
              ...VerificationError,
              isError: true,
              errorMessage: error?.response?.data?.key ?? "Unkonwn Error!",
              isCodeExpire: true,
            });
          } else {
            setRequetStart(false);
            setVerificationError({
              ...VerificationError,
              isError: true,
              errorMessage: error?.response?.data?.key ?? "Unkonwn Error!",
            });
          }
        });
    }
  }
  function resendVerificationCodeHandler() {
    setRequetStart(true);
    ResendVerificationCode(userDetail?.user?.id)
      .then((res) => {
        if (res.status === 200) {
          setRequetStart(false);
          // toast.success("Verification Code Sent!", {
          //   position: toast.POSITION.TOP_RIGHT,
          //   transition: Flip,
          // });
        }
      })
      .catch((error) => {});
  }
  useEffect(() => {
    if (SignUpState || SignIn) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
      document.body.style.width = "auto";
    }
  }, [SignUpState, SignIn]);

  const [step, setStep] = useState(1);
  return (
    <>
      <ToastContainer autoClose={2000} />
      {SignUpState && (
        <>
          <Popup
            setTrigger={setSignUpState}
            modelType="SignUp"
            ShowCrossButton={ShowCrossButton}
            cross={true}
            onClose={() => {
              setSignUpState(false);
              setUserType(null);
              setStep(1);
            }}
          >
            {RequestStart && <Spinner />}
            {/* Conditional Components Starts */}
            {step === 1 && (
              <>
                <div className="bg-pastalblue w-full h-full">
                  <Heading
                    variant={"h6"}
                    className={
                      "flex items-center justify-center mt-20 xl:mt-[120px]"
                    }
                  >
                    Create your account
                  </Heading>
                  <div className="mt-[60px]">
                    <form>
                      <div className="flex justify-center">
                        <div className="w-fit mx-auto">
                          <TextFiled
                            label={"Email"}
                            variant={"medium"}
                            type="email"
                            name="email"
                            placeholder={"Enter your email address"}
                            onChange={formik?.handleChange}
                            value={formik?.values?.email}
                            className={"bg-white autofill:bg-white"}
                          />
                          {formik.touched.email && formik.errors.email ? (
                            <div className="text-alertred">
                              <SpecialText
                                variant={"FootNoteDisclaimer"}
                                className={"mt-1"}
                              >
                                {formik.errors.email}
                              </SpecialText>
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="my-5 flex justify-center mx-[5%] md:mx-[0%]">
                        <div className="w-fit mx-auto">
                          <label className="mb-2 block">
                            <AuxiliaryText
                              variant={"FieldLabel"}
                              className={"text-offblack"}
                            >
                              Password
                            </AuxiliaryText>
                          </label>
                          <PasswordField
                            passwordtype={PasswordType}
                            setpasswordtype={setPasswordType}
                            name="password"
                            placeholder={"Create a strong password"}
                            onChange={formik?.handleChange}
                            value={formik?.values?.password}
                          />
                          <div
                            className={
                              formik.touched.password && formik.errors.password
                                ? "flex justify-between mt-1"
                                : "flex justify-end mt-1"
                            }
                          >
                            {formik.touched.password &&
                            formik.errors.password ? (
                              <div className="text-alertred">
                                <SpecialText variant={"FootNoteDisclaimer"}>
                                  {formik.errors.password}
                                </SpecialText>
                              </div>
                            ) : null}
                            <div>
                              <button
                                className="flex items-center"
                                type="button"
                                onClick={() => {
                                  setSignUpState(false);
                                  setSignIn(true);
                                }}
                              >
                                <AuxiliaryText
                                  variant={"Placeholder"}
                                >
                                  Already have an account?
                                </AuxiliaryText>
                                <InteractiveText variant={"ActionLink"}>
                                  <span className="text-primaryblue cursor-pointer">
                                    &nbsp;Sign in
                                  </span>
                                </InteractiveText>
                              </button>
                            </div>
                          </div>
                          <div className="flex justify-center mt-[60px]">
                            <MainButton
                              variant={"large"}
                              onClick={
                                // formik.handleSubmit
                                () => {
                                  setStep(2);
                                }
                              }
                            >
                              Create
                            </MainButton>
                          </div>
                          <div className="flex items-center justify-center my-3 md:my-5">
                            <AuxiliaryText
                              variant={"Infographic"}
                              className={"text-center"}
                            >
                              or
                            </AuxiliaryText>
                          </div>
                          <div className="flex flex-col items-center justify-center">
                            <MainButton variant={"google"}>
                              <i className="fi fi-brands-google text-white size-4 pt-1"></i>
                            </MainButton>
                          </div>
                        </div>
                      </div>
                      <WrittenStepper
                        currentStep={step}
                        lastStep={8}
                        currentLabel={"Create your account"}
                        marginTop={"fixed bottom-40 left-0 w-full"}
                      />

                      {/* Terms and Conditions */}
                      <div className="flex justify-center items-end fixed bottom-2 left-0 w-full">
                        <SpecialText
                          variant={"FootNoteDisclaimer"}
                          className="mt-auto xl:w-full text-center"
                        >
                          By continuing, you're agree to tradesposter
                          <span
                            className="text-primaryblue cursor-pointer"
                            onClick={() => {
                              setSignUpState(false);
                              navigate("/terms-&-conditions");
                            }}
                          >
                            &nbsp;Terms & Conditions.
                          </span>
                        </SpecialText>
                      </div>
                    </form>
                  </div>
                </div>
                {/* Sign Up form UI Ends */}
              </>
            )}
            {step === 2 && (
              <>
                <Verify
                  closePopup={() => {
                    setStep(3);
                    // setSignUpState(false);
                  }}
                />
                <WrittenStepper
                  currentStep={step}
                  lastStep={8}
                  currentLabel={"Verify your email"}
                  marginTop={"fixed bottom-40 left-0 w-full"}
                />
              </>
            )}
            {step === 3 && (
              <>
                <Heading
                  variant={"h6"}
                  className={
                    "flex items-center justify-center text-center mt-20 xl:mt-[120px] text-offblack"
                  }
                >
                  Choose your account
                </Heading>
                <div className="mt-[60px]">
                  <div className="flex flex-col lg:flex-row justify-center items-center gap-8">
                    {/* Business */}
                    <div
                      onClick={() => setUserType(USER_TYPE.SELLLER)}
                      className={`rounded-3xl flex items-center w-[350px] h-[200px] sm:w-[450px] sm:h-[200px] md:w-[500px] md:h-[250px] lg:w-[450px] lg:h-[250px] xl:w-[500px] xl:h-[250px] 2xl:w-[616px] 2xl:h-[300px] px-5 md:px-[56px] hover:cursor-pointer hover:scale-105 ${
                        UserType === USER_TYPE.SELLLER
                          ? "bg-primaryblue text-white"
                          : "bg-white text-offblack"
                      }`}
                    >
                      <div className="w-full">
                        <div className="mt-5">
                          <i className="fi fi-rs-briefcase text-[30px] xl:text-5xl 2xl:text-[50px]"></i>
                        </div>
                        <div>
                          <SecondaryHeading
                            variant={"MainParagraphHeading"}
                            className={"mt-3"}
                          >
                            I am a business
                          </SecondaryHeading>
                        </div>
                        <div className="mt-3">
                          <Paragraph
                            variant={"MainParagraph"}
                            className={
                              "h-[54px] sm:h-[52px] md:h-[38px] lg:h-[40px] xl:h-[58px] 2xl:h-[50px] overflow-hidden text-ellipsis"
                            }
                          >
                            List your services and connect with customers.
                          </Paragraph>
                        </div>
                      </div>
                    </div>
                    {/* Customer */}
                    <div
                      onClick={() => setUserType(USER_TYPE.CUSTOMER)}
                      className={`rounded-3xl flex items-center w-[350px] h-[200px] sm:w-[450px] sm:h-[200px] md:w-[500px] md:h-[250px] lg:w-[450px] lg:h-[250px] xl:w-[500px] xl:h-[250px] 2xl:w-[616px] 2xl:h-[300px] px-5 md:px-[56px] hover:cursor-pointer hover:scale-105 ${
                        UserType === USER_TYPE.CUSTOMER
                          ? "bg-primaryblue text-white"
                          : "bg-white text-offblack"
                      }`}
                    >
                      <div className="w-full">
                        <div className="mt-5">
                          <i className="fi fi-rs-user text-[30px] xl:text-5xl 2xl:text-[50px]"></i>
                        </div>
                        <div>
                          <SecondaryHeading
                            variant={"MainParagraphHeading"}
                            className={"mt-3"}
                          >
                            I am a customer
                          </SecondaryHeading>
                        </div>
                        <div className="mt-3">
                          <Paragraph
                            variant={"MainParagraph"}
                            className={
                              "h-[54px] sm:h-[52px] md:h-[38px] lg:h-[40px] xl:h-[58px] 2xl:h-[50px] overflow-hidden text-ellipsis"
                            }
                          >
                            Find services and connect with businesses.
                          </Paragraph>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-end mt-5 md:mt-10 mx-[5%] xl:mx-auto xl:w-[1100px] 2xl:w-[1264px]">
                    <MainButton
                      type="button"
                      variant={"extrasmall"}
                      onClick={() => {
                        setStep(4);
                      }}
                    >
                      Next
                    </MainButton>
                  </div>
                </div>

                <WrittenStepper
                  currentStep={step}
                  lastStep={8}
                  currentLabel={"Choose your account type"}
                  marginTop={"fixed bottom-40 left-0 w-full"}
                />
              </>
            )}
            {step === 4 && (
              <>
                {UserType === USER_TYPE.CUSTOMER ? (
                  <CustomerInfo onClickHandler={() => {
                      setSignUpState(false);
                      setUserType(null);
                      setStep(1);
                    }}/>
                ) : (
                  <BusinessInfo
                    onClickHandler={() => {
                      setSignUpState(false);
                      setUserType(null);
                      setStep(1);
                    }}
                  />
                )}
              </>
            )}

            {/* Conditional Components Ends */}
          </Popup>
        </>
      )}
    </>
  );
};
