import React from "react";
import Snippet from "../SnippetIcon/Snippet";

const TextFiled = ({
  className,
  label,
  placeholder,
  variant,
  snippet,
  SnippetText,
  star,
  ...restProps
}) => {
  return (
    <>
      <Snippet
        variant={"label"}
        label={label}
        text={SnippetText}
        snippet={snippet}
        star={star}
      />
      {variant === "large" && (
        <>
          <input
            {...restProps}
            name={restProps?.name}
            placeholder={placeholder}
            className={` placeholder-lightgray placeholder:font-inter placeholder:font-normal placeholder:normal-case placeholder:leading-[1.4] placeholder:tracking-[0px] placeholder:text-[11px] placeholder:sm:text-[11px] placeholder:md:text-[12px] placeholder:lg:text-[12px] placeholder:xl:text-[12px] placeholder:2xl:text-[13px] placeholder:3xl:text-[13px] text-offblack font-inter font-normal normal-case text-[11px] leading-[1.4] tracking-[0px] sm:text-[11px] md:text-[12px] lg:text-[12px] xl:text-[12px] 2xl:text-[13px] 3xl:text-[13px] rounded-lg w-[320px] h-[46px] sm:w-[380px] sm:h-[50px] md:w-[400px] md:h-[54px] lg:w-[420px] lg:h-[56px] xl:w-[420px] xl:h-[56px] 2xl:w-[500px] 2xl:h-[56px] block p-3 ${className}`}
          />
        </>
      )}
      {variant === "latest" && (
        <>
          <input
            {...restProps}
            name={restProps?.name}
            placeholder={placeholder}
            className={` placeholder-lightgray placeholder:font-inter placeholder:font-normal placeholder:normal-case placeholder:leading-[1.4] placeholder:tracking-[0px] placeholder:text-[11px] placeholder:sm:text-[11px] placeholder:md:text-[12px] placeholder:lg:text-[12px] placeholder:xl:text-[12px] placeholder:2xl:text-[13px] placeholder:3xl:text-[13px] text-offblack font-inter font-normal normal-case text-[11px] leading-[1.4] tracking-[0px] sm:text-[11px] md:text-[12px] lg:text-[12px] xl:text-[12px] 2xl:text-[13px] 3xl:text-[13px] rounded-lg w-[320px] h-[46px] sm:w-[380px] sm:h-[50px] md:w-[400px] md:h-[54px] lg:w-[400px] lg:h-[56px] block p-3 ${className}`}
          />
        </>
      )}
      {variant === "medium" && (
        <>
          <input
            {...restProps}
            name={restProps?.name}
            placeholder={placeholder}
            className={`focus:border focus:border-primaryblue placeholder-lightgray placeholder:font-inter placeholder:font-normal placeholder:normal-case placeholder:leading-[1.4] placeholder:tracking-[0px] placeholder:text-[11px] placeholder:sm:text-[11px] placeholder:md:text-[12px] placeholder:lg:text-[12px] placeholder:xl:text-[12px] placeholder:2xl:text-[13px] placeholder:3xl:text-[13px] text-offblack font-inter font-normal normal-case text-[11px] leading-[1.4] tracking-[0px] sm:text-[11px] md:text-[12px] lg:text-[12px] xl:text-[12px] 2xl:text-[13px] 3xl:text-[13px] rounded-lg w-[300px] h-[46px] sm:w-[350px] sm:h-[50px] md:w-[370px] md:h-[54px] lg:w-[370px] lg:h-[56px] block p-3 ${className}`}
          />
        </>
      )}
      {variant === "small" && (
        <>
          <input
            {...restProps}
            name={restProps?.name}
            placeholder={placeholder}
           className={`focus:border focus:border-primaryblue placeholder-lightgray placeholder:font-inter placeholder:font-normal placeholder:normal-case placeholder:leading-[1.4] placeholder:tracking-[0px] placeholder:text-[11px] placeholder:sm:text-[11px] placeholder:md:text-[12px] placeholder:lg:text-[12px] placeholder:xl:text-[12px] placeholder:2xl:text-[13px] placeholder:3xl:text-[13px] text-offblack font-inter font-normal normal-case text-[11px] leading-[1.4] tracking-[0px] sm:text-[11px] md:text-[12px] lg:text-[12px] xl:text-[12px] 2xl:text-[13px] 3xl:text-[13px] rounded-lg w-[270px] h-[44px] sm:w-[290px] sm:h-[48px] md:w-[290px] md:h-[52px] lg:w-[290px] lg:h-[54px] block p-3 ${className}`}
          />
        </>
      )}
      {variant === "exsmall" && (
        <>
          <input
            {...restProps}
            name={restProps?.name}
            placeholder={placeholder}
            className={`focus:border focus:border-primaryblue placeholder-lightgray placeholder:font-inter placeholder:font-normal placeholder:normal-case placeholder:leading-[1.4] placeholder:tracking-[0px] placeholder:text-[11px] placeholder:sm:text-[11px] placeholder:md:text-[12px] placeholder:lg:text-[12px] placeholder:xl:text-[12px] placeholder:2xl:text-[13px] placeholder:3xl:text-[13px] text-offblack font-inter font-normal normal-case text-[11px] leading-[1.4] tracking-[0px] sm:text-[11px] md:text-[12px] lg:text-[12px] xl:text-[12px] 2xl:text-[13px] 3xl:text-[13px] rounded-lg w-[240px] h-[42px] sm:w-[250px] sm:h-[46px] md:w-[250px] md:h-[50px] lg:w-[250px] lg:h-[52px] block p-3 ${className}`}
          />
        </>
      )}
      {variant === "extrasmall01" && (
        <>
          <input
            {...restProps}
            name={restProps?.name}
            placeholder={placeholder}
            className={`focus:border focus:border-primaryblue placeholder-lightgray placeholder:font-inter placeholder:font-normal placeholder:normal-case placeholder:leading-[1.4] placeholder:tracking-[0px] placeholder:text-[11px] placeholder:sm:text-[11px] placeholder:md:text-[12px] placeholder:lg:text-[12px] placeholder:xl:text-[12px] placeholder:2xl:text-[13px] placeholder:3xl:text-[13px] text-offblack font-inter font-normal normal-case text-[11px] leading-[1.4] tracking-[0px] sm:text-[11px] md:text-[12px] lg:text-[12px] xl:text-[12px] 2xl:text-[13px] 3xl:text-[13px] rounded-lg text-center w-[70px] h-[46px] sm:h-[50px] md:h-[54px] lg:h-[56px] block p-3 ${className}`}
          />
        </>
      )}
      {variant === "extrasmall02" && (
        <>
          <input
            {...restProps}
            name={restProps?.name}
            placeholder={placeholder}
            className={`focus:border focus:border-primaryblue placeholder-lightgray placeholder:font-inter placeholder:font-normal placeholder:normal-case placeholder:leading-[1.4] placeholder:tracking-[0px] placeholder:text-[11px] placeholder:sm:text-[11px] placeholder:md:text-[12px] placeholder:lg:text-[12px] placeholder:xl:text-[12px] placeholder:2xl:text-[13px] placeholder:3xl:text-[13px] text-offblack font-inter font-normal normal-case text-[11px] leading-[1.4] tracking-[0px] sm:text-[11px] md:text-[12px] lg:text-[12px] xl:text-[12px] 2xl:text-[13px] 3xl:text-[13px] rounded-lg text-center w-[90px] h-[46px] sm:h-[50px] md:h-[54px] lg:h-[56px] block p-3 ${className}`}
          />
        </>
      )}

      {variant === "general" && (
        <>
          <input
            {...restProps}
            name={restProps?.name}
            placeholder={placeholder}
            className={`focus:border focus:border-primaryblue placeholder-lightgray placeholder:font-inter placeholder:font-normal placeholder:normal-case placeholder:leading-[1.4] placeholder:tracking-[0px] placeholder:text-[11px] placeholder:sm:text-[11px] placeholder:md:text-[12px] placeholder:lg:text-[12px] placeholder:xl:text-[12px] placeholder:2xl:text-[13px] placeholder:3xl:text-[13px] text-offblack font-inter font-normal normal-case text-[11px] leading-[1.4] tracking-[0px] sm:text-[11px] md:text-[12px] lg:text-[12px] xl:text-[12px] 2xl:text-[13px] 3xl:text-[13px] rounded-lg w-full h-[46px] sm:h-[50px] md:h-[54px] lg:h-[56px] block p-3 ${className}`}
          />
        </>
      )}
    </>
  );
};

export default TextFiled;

