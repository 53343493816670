import React, { useRef } from "react";
import { Pagination, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from "swiper/react";
import CircularButton from "../../../components/common/MainButton/CircularButton";
import DashboardAdsCard from "../../../components/common/AdsCard/DashboardAdsCard";
import { businessCategories } from "../../Home/BusinessCategories";

const PostSlider = () => {
  const swiperRef = useRef();
  return (
    <>
      <div className="lg:w-full mt-5 3xl:mt-10">
        <Swiper
          className="w-auto lg:w-full"
          breakpoints={{
            300: {
              slidesPerView: 1,
            },
            640: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: "auto",
            },
          }}
          // centeredSlides={true}
          //   spaceBetween={32}
          initialSlide={0}
          speed={1000}
          // loop
          spaceBetween={32}
          modules={[Pagination, Navigation]}
          onBeforeInit={(swiper) => {
            swiperRef.current = swiper;
          }}
        >
          <>
            {businessCategories?.map((e) => {
              return (
                <SwiperSlide className="w-fit">
                  <DashboardAdsCard companyName={e.businessCategoryName} />
                </SwiperSlide>
              );
            })}
          </>
        </Swiper>
        <div className="hidden lg:flex items-center justify-end gap-x-2 mt-5">
          <CircularButton
            variant={"prev30"}
            onClick={() => swiperRef.current?.slideNext()}
          ></CircularButton>
          <CircularButton
            variant={"next30"}
            onClick={() => swiperRef.current?.slidePrev()}
          ></CircularButton>
        </div>
      </div>
    </>
  );
};

export default PostSlider;
