import React from "react";

const Heading = ({ variant, children, className }) => {
  return (
    <>
      {/* o	Usage: Main titles on landing pages and primary headers. */}
      {variant === "h1" && (
        <h1
          className={`
          ${className} font-inter font-bold uppercase leading-[1.1] tracking-[0.5px] text-[44px] sm:text-[54px] md:text-[60px] lg:text-[68px] xl:text-[72px] 2xl:text-[76px] 3xl:text-[78px] text-offblack`}
        >
          {children}
        </h1>
      )}

      {/* o	Usage: Titles for main sections. */}
      {variant === "h2" && (
        <h2
          className={`
          ${className} font-inter font-semibold capitalize leading-[1.2] tracking-[0.15px] text-[36px] sm:text-[42px] md:text-[48px] lg:text-[50px] xl:text-[54px] 2xl:text-[60px] 3xl:text-[64px]`}
        >
          {children}
        </h2>
      )}

      {/* o	Usage: Titles for subsections. */}
      {variant === "h3" && (
        <h3
          className={`
         ${className} font-inter font-semibold leading-[1.3] tracking-[0px] text-[28px] sm:text-[34px] md:text-[38px] lg:text-[40px] xl:text-[44px] 2xl:text-[50px] 3xl:text-[54px] text-offblack`}
        >
          {children}
        </h3>
      )}

      {/* o	Usage: Titles for smaller sections or categories. */}
      {variant === "h4" && (
        <h4
          className={`
         ${className} font-inter font-[500] leading-[1.35] tracking-[0px] text-[23px] sm:text-[28px] md:text-[32px] lg:text-[34px] xl:text-[36px] 2xl:text-[42px] 3xl:text-[46px] text-offblack`}
        >
          {children}
        </h4>
      )}

      {/* o	Usage: Titles within content or sidebar headings. */}
      {variant === "h5" && (
        <h5
          className={`
         ${className} font-inter font-[500] leading-[1.4] tracking-[0px] text-[19px] sm:text-[22px] md:text-[26px] lg:text-[26px] xl:text-[28px] 2xl:text-[30px] 3xl:text-[34px]`}
        >
          {children}
        </h5>
      )}
      {/* o	Usage: Least prominent headings, widget titles, captions. */}
      {variant === "h6" && (
        <h6
          className={`
         ${className} font-inter font-[500] leading-[1.45] capitalize tracking-[0px] text-[17px] sm:text-[20px] md:text-[22px] lg:text-[20px] xl:text-[22px] 2xl:text-[24px] 3xl:text-[26px]`}
        >
          {children}
        </h6>
      )}
    </>
  );
};
export default Heading;
