import React from "react";
import Heading from "../../components/common/Typography/Heading";
import ReactTyped from "react-typed";
const MainBanner = () => {
  const services = [
    "Air conditioning and Heating",
    "Architects and Design",
    "Blinds and Awning",
    "Bricklaying",
    "Cabinetmakers",
    "Carpentry",
    "Carpet and Flooring",
    "Caulking",
    "Cctv and Data",
    "Cleaning",
    "Concrete and Paving",
    "Conveyancing",
    "Decking and Gazebos",
    "Demolition and Excavation",
    "Electricals",
    "Engineering",
    "Fence and Gates ",
    "Garden and Landscaping",
    "Glazing and Screens",
    "Handyman",
    "Inspection and Surveyors",
    "Insulations",
    "Locksmiths",
    "Painting and Decorating",
    "Pest Control",
    "Plastering ",
    "Plumbing",
    "Rendering",
    "Renovations",
    "Roofing",
    "Scaffolding",
    "Shop Fitters ",
    "Skip Bins ",
    "Solar Panels",
    "Staircases",
    "Stonemasons",
    "Tilling",
    "Waterproofing",
  ];

  return (
    <>
      <div
        className={`w-[100%] h-screen sm:h-[1000px] lg:h-screen relative bg-no-repeat bg-center object-cover bg-cover main-banner`}
      >
        <div className="mx-[5%] xl:mx-auto xl:w-[1100px] 2xl:w-[1264px] h-full ">
          <Heading
            variant={"h1"}
            className={
              "text-start text-white w-full pt-[50vh] sm:pt-[50vh] md:pt-[50vh] lg:pt-[50vh] xl:pt-[50vh] 2xl:pt-[500px]"
            }
          >
            Connect. Collaborate. Complete.
          </Heading>
          <Heading
            variant={"h6"} className={"text-white"}>
            <ReactTyped
              strings={services}
              typeSpeed={100}
              backSpeed={100}
              loop
            />
          </Heading>
        </div>
      </div>
    </>
  );
};
export default MainBanner;
