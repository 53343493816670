import React, { useState } from "react";
import TextFiled from "../../../components/common/InputField/TextFiled";
import MainButton from "../../../components/common/MainButton/MainButton";
import Snippet from "../../../components/common/SnippetIcon/Snippet";
import Heading from "../../../components/common/Typography/Heading";
import { Link } from "react-router-dom";
import InteractiveText from "../../../components/common/Typography/InteractiveText";
import ButtonText from "../../../components/common/Typography/ButtonText";
import Paragraph from "../../../components/common/Typography/Paragraph";
import SpecialText from "../../../components/common/Typography/SpecialText";
import WrittenStepper from "../../../components/common/StepIndicater/WrittenStepper";
import Title from "../../../components/common/Title/Title";
import { Dropdown } from "primereact/dropdown";

const BusinessInfo = ({ onClickHandler }) => {
  const [mainCategory, setMainCategory] = useState(null);
  const [step, setStep] = useState(4);
  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);

      // Generate a preview URL for the selected image
      const reader = new FileReader();
      reader.onload = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const servicesNames = [
    { name: "Air conditioning & Heating" },
    { name: "Architectural Services" },
    { name: "Bricklaying & Blockwork" },
    { name: "Cabinet Making" },
    { name: "Carpentry" },
    { name: "Caulking Services" },
    { name: "Cleaning Services" },
    { name: "Concrete & Paving" },
    { name: "Conveyancing" },
    { name: "Decking, Gazebo & Carports" },
    { name: "Demolition & Excavation" },
    { name: "Electrical Services" },
    { name: "Engineering Services" },
    { name: "Fencing & Gates" },
    { name: "Garden & Landscaping" },
    { name: "Glass & Glazing" },
    { name: "Handyman Services" },
    { name: "Inspections & Surveyors" },
    { name: "Insulation Services" },
    { name: "Interior Design" },
    { name: "Locksmiths" },
    { name: "Mould & Asbestos" },
    { name: "Moving & Delivery" },
    { name: "Painting & Decorating" },
    { name: "Pest Control" },
    { name: "Plumbing" },
    { name: "Pools & Spas" },
    { name: "Rendering" },
    { name: "Renovation & Restoration" },
    { name: "Roofing" },
    { name: "Scaffolding" },
    { name: "Shopfitting" },
    { name: "Skip Bins" },
    { name: "Solar Power" },
    { name: "Staircase Services" },
    { name: "Stonework" },
    { name: "Tiling" },
    { name: "Waterproofing" },
  ];
  return (
    <>
      <div className="w-[320px] sm:w-[380px] md:w-[400px] mx-auto">
        {step === 4 && (
          <>
            <Heading
              variant={"h6"}
              className={
                "flex items-center justify-center text-center mt-20 xl:mt-[120px] text-offblack"
              }
            >
              Business information
            </Heading>
            <div className="mt-[60px]">
              <form>
                <div className="flex justify-center">
                  <div className="w-fit mx-auto">
                    <div>
                      <TextFiled
                        className={"lg:bg-white"}
                        variant={"latest"}
                        label={"ABN"}
                        name="ABN"
                        placeholder={
                          "Enter your Australian Business Number (ABN)"
                        }
                        star={true}
                      />
                      <div className="flex items-center justify-end text-primaryblue mt-2">
                        <Link
                          to={"https://abr.business.gov.au/"}
                          target="_blank"
                        >
                          <InteractiveText variant={"ActionLink"}>
                            Look up (ABN)
                          </InteractiveText>
                        </Link>
                      </div>
                    </div>
                    <div>
                      <TextFiled
                        variant={"latest"}
                        label={"Business name"}
                        placeholder={"Enter your business name"}
                        star={true}
                      />
                    </div>
                    <div className="mt-5">
                      <TextFiled
                        variant={"latest"}
                        label={"Company name (Optional)"}
                        placeholder={"Enter your business name"}
                      />
                    </div>

                    <div className="flex items-center justify-end mt-10 w-full ">
                      <MainButton
                        variant={"extrasmall"}
                        onClick={() => {
                          setStep(5);
                        }}
                      >
                        Next
                      </MainButton>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <WrittenStepper
              currentStep={step}
              lastStep={8}
              currentLabel={"Business information"}
              marginTop={"fixed bottom-40 left-0 w-full"}
            />
          </>
        )}
        {step === 5 && (
          <>
            <Heading
              variant={"h6"}
              className={
                "flex items-center justify-center text-center mt-20 xl:mt-[120px] text-offblack"
              }
            >
              Upload your logo
            </Heading>

            <div className="w-[320px] sm:w-[380px] md:w-[400px] mx-auto mt-10 xl:mt-[60px]">
              <div className="relative size-[192px] mx-auto">
                <div className="size-[192px]">
                  {selectedFile && (
                    <img
                      className="object-center rounded-full w-full h-full cursor-pointer"
                      src={imagePreview || URL.createObjectURL(selectedFile)}
                      // src={
                      //   !companyLogo || companyLogo === "null"
                      //     ? require("../../../assets/img/hero-vector.svg").default
                      //     : companyLogo
                      // }
                      alt={"logo"}
                      // onError={(e) => {
                      //     e.target.src = require("../../assets/img/default-image.jpg");
                      //   }}
                    />
                  )}
                  {!selectedFile && (
                    <div className="object-center rounded-full w-full h-full cursor-pointer bg-white" />
                  )}
                </div>
                <div className="absolute z-10 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                  <div
                    className={`flex justify-center items-center rounded-full size-[30px] pt-[2px] relative ${selectedFile && "bg-white"}`}
                  >
                    <div>
                      <input
                        type="file"
                        accept="image/*" // Accept only image files
                        onChange={handleFileInputChange}
                        id="logoPicture"
                        className="cursor-pointer hidden"
                      />
                      <label
                        role="button"
                        htmlFor="logoPicture"
                        className="flex justify-center bg-transparent"
                      >
                      {
                        !selectedFile ?
                        <Title text={"Upload"} width={"50"}>
                          <i className="fi fi-rs-upload text-base text-mediumgray hover:text-primaryblue"></i>
                        </Title>
                        : <Title text={"Edit"} width={"50"}>
                          <i className="fi fi-rs-pen-circle text-sm text-mediumgray hover:text-primaryblue"></i>
                        </Title>
                      }
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-between mt-10 w-full">
                <div onClick={() => setStep(6)}>
                  <ButtonText
                    variant={"SecondaryText"}
                    className={"hover:text-primaryblue hover:cursor-pointer"}
                  >
                    Skip for now
                  </ButtonText>
                </div>
                <MainButton
                  variant={"extrasmall"}
                  onClick={() => {
                    setStep(6);
                  }}
                >
                  Next
                </MainButton>
              </div>
            </div>
            <WrittenStepper
              currentStep={step}
              lastStep={8}
              currentLabel={"Upload your logo"}
              marginTop={"fixed bottom-40 left-0 w-full"}
            />
          </>
        )}
        {step === 6 && (
          <>
            <Heading
              variant={"h6"}
              className={
                "flex items-center justify-center text-center mt-20 xl:mt-[120px] text-offblack"
              }
            >
              Select your service
            </Heading>
            <div className="mt-[60px]">
              <div className="flex">
                <Snippet
                  variant={"label"}
                  snippet={true}
                  star={true}
                  text={
                    "Select the main category that best represents your business, for example, carpentry."
                  }
                  label={"Select main service category"}
                />
              </div>
              <div>
                <Dropdown
                  value={mainCategory}
                  onChange={(e) => setMainCategory(e.value)}
                  options={servicesNames}
                  optionLabel="name"
                  placeholder="Choose 1 main category"
                  filter
                  className={`custom-dropdown-signup bg-white text-offblack items-center focus:border block font-inter font-normal normal-case text-[11px] leading-[1.4] tracking-[0px] sm:text-[11px] md:text-[12px] lg:text-[12px] xl:text-[12px] 2xl:text-[13px] 3xl:text-[13px] rounded-lg w-full h-[50px] sm:h-[50px] md:h-[54px] lg:h-[54px] xl:h-[56px] 2xl:h-[56px] py-1 px-2.5 `}
                />
              </div>
              <div className="flex items-center justify-end mt-10 w-full">
                <MainButton
                  variant={"extrasmall"}
                  onClick={() => {
                    setStep(7);
                  }}
                >
                  Next
                </MainButton>
              </div>
            </div>
            <WrittenStepper
              currentStep={step}
              lastStep={8}
              currentLabel={"Select your service"}
              marginTop={"fixed bottom-40 left-0 w-full"}
            />
          </>
        )}
        {step === 7 && (
          <>
            <Heading
              variant={"h6"}
              className={
                "flex items-center justify-center text-center mt-20 xl:mt-[120px] text-offblack"
              }
            >
              Set your location
            </Heading>
            <div className="mt-[60px]">
              <TextFiled
                variant={"latest"}
                label={"Location"}
                placeholder={"Start typing your suburb or postcode."}
                star={true}
              />
            </div>
            <div className="flex items-center justify-end mt-10 w-full">
              <MainButton
                variant={"extrasmall"}
                onClick={() => {
                  setStep(8);
                }}
              >
                Next
              </MainButton>
            </div>
            <WrittenStepper
              currentStep={step}
              lastStep={8}
              currentLabel={"Set your location"}
              marginTop={"fixed bottom-40 left-0 w-full"}
            />
          </>
        )}
        {step === 8 && (
          <>
            <Heading
              variant={"h6"}
              className={
                "flex items-center justify-center text-center mt-20 xl:mt-[120px] text-offblack"
              }
            >
              Confirmation & Profile preview
            </Heading>
            <div className="max-w-[400px] mx-auto mt-[60px]">
              <Paragraph
                variant={"MainParagraph"}
                className={"text-offblack text-start"}
              >
                ABC, your profile is ready! You can now explore your dashboard,
                complete your profile, create listings, and connect with
                customers.
              </Paragraph>
            </div>
            <div className="size-[96px] mx-auto mt-5">
              {selectedFile && (
                <img
                  className="object-center rounded-full w-full h-full"
                  src={imagePreview || URL.createObjectURL(selectedFile)}
                  // src={
                  //   !companyLogo || companyLogo === "null"
                  //     ? require("../../../assets/img/hero-vector.svg").default
                  //     : companyLogo
                  // }
                  alt={"user"}
                  // onError={(e) => {
                  //     e.target.src = require("../../assets/img/default-image.jpg");
                  //   }}
                />
              )}
              {!selectedFile && (
                <div className="object-center rounded-full w-full h-full bg-white" />
              )}
            </div>
            <div className="w-full text-center">
              <div className="mt-3">
                <SpecialText variant={"OccupationLocation"}>
                  Waterproofing company
                </SpecialText>
              </div>
              <div className="mt-1">
                <SpecialText variant={"OccupationLocation"}>
                  Waterproofing
                </SpecialText>
              </div>
              <div className="mt-1">
                <SpecialText variant={"OccupationLocation"}>
                  Duffy, Australian Capital Territory, 2611
                </SpecialText>
              </div>
              <div className="mt-1">
                <SpecialText variant={"Date"}>
                  Member since Dec&nbsp;2007
                </SpecialText>
              </div>
            </div>
            <div className="flex items-center justify-center mt-10 w-full">
              <MainButton variant={"medium"} onClick={onClickHandler}>
                Dashboard
              </MainButton>
            </div>
            <WrittenStepper
              currentStep={step}
              lastStep={8}
              currentLabel={"Confirm & Preview profile"}
              marginTop={"fixed bottom-40 left-0 w-full"}
            />
          </>
        )}
      </div>
    </>
  );
};

export default BusinessInfo;
