import React from "react";
import MainButton from "../../../components/common/MainButton/MainButton";
import TextFiled from "../../../components/common/InputField/TextFiled";
import { useState } from "react";
import Heading from "../../../components/common/Typography/Heading";
import Paragraph from "../../../components/common/Typography/Paragraph";
import InteractiveText from "../../../components/common/Typography/InteractiveText";
import AuxiliaryText from "../../../components/common/Typography/AuxiliaryText";
const Verify = ({ label, closePopup }) => {
  const [step, setStep] = useState("A");

  return (
    <>
      {step === "A" && (
        <div className="w-[300px] sm:w-[350px] md:w-[380px] lg:w-[400px] mx-auto mt-20 xl:mt-[120px]">
          <Heading variant={"h6"} className={"text-center text-offblack"}>
            Verify your email
          </Heading>
          <div className="mx-auto rounded-lg mb-[16px] w-full mt-[60px]">
            <div className="max-w-[400px] mx-auto">
              <Paragraph
                variant={"MainParagraph"}
                className={"text-offblack text-start"}
              >
                We've sent a verification link to your email. Please click the
                link or enter the 6-digit code to verify your account.
              </Paragraph>
            </div>
            <div className="mt-10 w-full">
              <TextFiled
                variant={"latest"}
                label={label}
                placeholder={"Input the code here."}
              />
              <div className="flex items-center justify-between mt-1">
                <AuxiliaryText variant={"Infographic"} className={"mr-2"}>
                  Didn’t get the code?
                </AuxiliaryText>
                <InteractiveText
                  variant={"ActionLink"}
                  className={"text-primaryblue cursor-pointer"}
                >
                  Resend
                </InteractiveText>
              </div>
            </div>
            <div className="flex items-center justify-end mt-10">
              <MainButton
                variant={"extrasmall"}
                onClick={() => {
                  setStep("B");
                  setTimeout(closePopup, 3000);
                }}
              >
                Next
              </MainButton>
            </div>
          </div>
        </div>
      )}

      {step === "B" && (
        <>
          <div className="flex items-center justify-center w-[300px] sm:w-[350px] md:w-[380px] lg:w-[400px] mt-[120px] mx-auto">
            {/* <i className="fi fi-sr-check text-[30px] text-primaryblue mr-5"></i> */}
            <Heading variant={"h6"} className={"text-center text-offblack"}>
              Email verified !!!
            </Heading>
          </div>
            <div className="max-w-[400px] mx-auto mt-[60px]">
              <Paragraph
                variant={"MainParagraph"}
                className={"text-offblack text-start"}
              >
                Your business email has been successfully verified. You're now
                ready to receive updates and notifications directly to your
                inbox.
              </Paragraph>
            </div>
        </>
      )}
    </>
  );
};

export default Verify;
